var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products-by-category"},[(_vm.category)?_c('v-card',{attrs:{"flat":"","min-height":"500"}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":"","align":"center","justify":"start"}},[_c('h2',{staticClass:"fw-700 primary--text"},[_vm._v(" Designs for "+_vm._s(_vm._f("ucwords")(_vm.category.title))+" ")]),_c('v-select',{staticClass:"col-md-2 col-sm-12 ml-md-4",attrs:{"hide-details":"auto","items":_vm.filters,"item-text":"title","item-value":"slug","outlined":"","dense":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),(_vm.canAddCategoryPrice())?_c('v-btn',{attrs:{"color":"primary text-none ma-1","depressed":""},on:{"click":function($event){$event.stopPropagation();_vm.admin_price_by_category_dialog = true}}},[_vm._v(" Add Admin Price ")]):_vm._e(),_c('v-spacer'),_c('v-autocomplete',{staticClass:"col-md-3 col-sm-12",attrs:{"items":_vm.searchResults,"loading":_vm.isSearchLoading,"search-input":_vm.searchKeyword,"color":"primary","hide-no-data":"","hide-selected":"","dense":"","hide-details":"auto","clearable":"","outlined":"","item-text":"title","item-value":"id","placeholder":"Search designs","prepend-inner-icon":"mdi-magnify","return-object":""},on:{"update:searchInput":function($event){_vm.searchKeyword=$event},"update:search-input":function($event){_vm.searchKeyword=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mx-1",attrs:{"tile":""}},[_c('Attachment',{attrs:{"item":item.previews[0],"prefer-thumb":true,"styles":"max-height:100%;width: auto;"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text fs-13",domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{staticClass:"primary--text fs-10",domProps:{"textContent":_vm._s(item.graphic_type.title)}})],1)],1)],1)]}}],null,false,3493024046),model:{value:(_vm.searchModel),callback:function ($$v) {_vm.searchModel=$$v},expression:"searchModel"}})],1)],1),_c('v-card-text',{staticClass:"px-5",staticStyle:{"background":"#f8f8f8"}},_vm._l((_vm.sortedSubs),function(sub){return _c('v-sheet',{key:sub.id,staticClass:"d-flex fullwidth pt-5 align-start flex-column",staticStyle:{"width":"100%"},attrs:{"dense":"","color":"#f8f8f8"}},[_c('div',{staticClass:"d-flex fullwidth align-center justify-end py-2"},[_c('label',{staticClass:"primary--text align-self-center mx-auto fw-700 fs-17"},[_vm._v(" "+_vm._s(_vm._f("ucwords")(sub.title))+" ")]),_c('router-link',{attrs:{"custom":"","to":{
              name: 'offers-by-subcategory',
              params: { id: sub.slug },
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('span',{staticClass:"cursor-pointer fw-500 fs-12 primary--text",on:{"click":navigate}},[_vm._v(" See more ")])]}}],null,true)})],1),(sub.products.length)?_c('v-chip-group',{attrs:{"show-arrows":"","active-class":"primary--text"}},_vm._l((sub.products),function(item){return _c('ProductCard',{key:item.id,attrs:{"has-title":false,"product":item,"has-price":"","max-width":250},on:{"addPrice":function($event){return _vm.openAddPriceByProduct(item)},"addToLikes":function($event){return _vm.add_to_likes(item, sub.id)},"deleteProduct":function($event){return _vm.confirmDeleteProduct(item)},"editProduct":function($event){return _vm.editProduct(item)},"viewProduct":function($event){return _vm.viewProduct(item)}}})}),1):_c('div',{staticClass:"d-flex fullwidth",staticStyle:{"height":"200px"}},[_c('Empty',{staticClass:"align-self-center mx-auto fullwidth",staticStyle:{"background":"#dedede !important"},attrs:{"icon":"mdi-image-off-outline","headline":("No designs found for " + (sub.title))}})],1)],1)}),1)],1):_vm._e(),_c('DeleteDialog',{attrs:{"title":"Delete Product","text-content":"Are you sure you want to delete this product? This will also delete all pending orders of this product "},on:{"delete":function($event){return _vm.deleteProduct(_vm.delete_callback)}},model:{value:(_vm.product_delete_dialog),callback:function ($$v) {_vm.product_delete_dialog=$$v},expression:"product_delete_dialog"}}),_c('AdminPriceByCategoryDialog',{attrs:{"category":_vm.category},on:{"save":_vm.handleSaveAdminPriceByCategory},model:{value:(_vm.admin_price_by_category_dialog),callback:function ($$v) {_vm.admin_price_by_category_dialog=$$v},expression:"admin_price_by_category_dialog"}}),_c('AdminPriceByProductDialog',{attrs:{"product":_vm.product},on:{"save":_vm.handleSaveAdminPriceByProduct},model:{value:(_vm.admin_price_by_product_dialog),callback:function ($$v) {_vm.admin_price_by_product_dialog=$$v},expression:"admin_price_by_product_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }