<template>
  <div class="products-by-category">
    <v-card flat v-if="category" min-height="500">
      <v-card-title>
        <v-row dense align="center" justify="start">
          <h2 class="fw-700 primary--text">
            Designs for {{ category.title | ucwords }}
          </h2>
          <v-select
            class="col-md-2 col-sm-12 ml-md-4"
            hide-details="auto"
            v-model="filter"
            :items="filters"
            item-text="title"
            item-value="slug"
            outlined
            dense
          ></v-select>
          <v-btn
            v-if="canAddCategoryPrice()"
            @click.stop="admin_price_by_category_dialog = true"
            color="primary text-none ma-1"
            depressed
          >
            Add Admin Price
          </v-btn>
          <v-spacer />
          <v-autocomplete
            v-model="searchModel"
            :items="searchResults"
            :loading="isSearchLoading"
            :search-input.sync="searchKeyword"
            color="primary"
            class="col-md-3 col-sm-12"
            hide-no-data
            hide-selected
            dense
            hide-details="auto"
            clearable
            outlined
            item-text="title"
            item-value="id"
            placeholder="Search designs"
            prepend-inner-icon="mdi-magnify"
            return-object
          >
            <template v-slot:item="{ item }">
              <span>
                <v-list-item>
                  <v-list-item-avatar tile class="mx-1">
                    <Attachment
                      :item="item.previews[0]"
                      :prefer-thumb="true"
                      :styles="`max-height:100%;width: auto;`"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text fs-13"
                      v-text="item.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="primary--text fs-10"
                      v-text="item.graphic_type.title"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
            </template>
          </v-autocomplete>
        </v-row>
      </v-card-title>
      <v-card-text class="px-5" style="background: #f8f8f8">
        <v-sheet
          dense
          color="#f8f8f8"
          style="width: 100%"
          class="d-flex fullwidth pt-5 align-start flex-column"
          v-for="sub in sortedSubs"
          :key="sub.id"
        >
          <div class="d-flex fullwidth align-center justify-end py-2">
            <label class="primary--text align-self-center mx-auto fw-700 fs-17">
              {{ sub.title | ucwords }}
            </label>
            <router-link
              custom
              :to="{
                name: 'offers-by-subcategory',
                params: { id: sub.slug },
              }"
              v-slot="{ navigate }"
            >
              <span
                class="cursor-pointer fw-500 fs-12 primary--text"
                @click="navigate"
              >
                See more
              </span>
            </router-link>
          </div>
          <v-chip-group
            v-if="sub.products.length"
            show-arrows
            active-class="primary--text"
          >
            <ProductCard
              :has-title="false"
              v-for="item in sub.products"
              :key="item.id"
              :product="item"
              has-price 
              :max-width="250"
              @addPrice="openAddPriceByProduct(item)"
              @addToLikes="add_to_likes(item, sub.id)"
              @deleteProduct="confirmDeleteProduct(item)"
              @editProduct="editProduct(item)"
              @viewProduct="viewProduct(item)"
            />
          </v-chip-group>
          <div style="height: 200px" class="d-flex fullwidth" v-else>
            <Empty
              icon="mdi-image-off-outline"
              class="align-self-center mx-auto fullwidth"
              style="background: #dedede !important"
              :headline="`No designs found for ${sub.title}`"
            ></Empty>
          </div>
        </v-sheet>
      </v-card-text>
    </v-card>
    <DeleteDialog
      v-model="product_delete_dialog"
      title="Delete Product"
      text-content="Are you sure you want to delete this product? This will also delete all pending orders of this product "
      @delete="deleteProduct(delete_callback)"
    />
    <AdminPriceByCategoryDialog
      v-model="admin_price_by_category_dialog"
      :category="category"
      @save="handleSaveAdminPriceByCategory"
    ></AdminPriceByCategoryDialog>
    <AdminPriceByProductDialog
      v-model="admin_price_by_product_dialog"
      :product="product"
      @save="handleSaveAdminPriceByProduct"
    ></AdminPriceByProductDialog>
  </div>
</template>

<script>
import ProductViewDialog from "../components/ProductViewDialog.vue";
import DeleteDialog from "@/common/DeleteDialog.vue";
import ProductCard from "../components/ProductCard.vue";
import AdminPriceByCategoryDialog from "../components/AdminPriceByCategoryDialog.vue";
import AdminPriceByProductDialog from "../components/AdminPriceByProductDialog.vue";
import request from "@/services/axios_instance";
import { offers_mixin } from "../mixin";
import _ from "lodash";
export default {
  name: "ProductByCategory",
  mixins: [offers_mixin],
  components: {
    ProductCard,
    DeleteDialog,
    ProductViewDialog,
    AdminPriceByCategoryDialog,
    AdminPriceByProductDialog,
  },
  data: () => ({
    category: null,
    product: null,
    info: [],
    loading_more: false,
    filter: "all",
    searchModel: null,
    searchResults: [],
    isSearchLoading: false,
    searchKeyword: null,
    admin_price_by_category_dialog: false,
    admin_price_by_product_dialog: false,
  }),
  computed: {
    sortedSubs() {
      if (
        !this.info ||
        !this.info.hasOwnProperty("graphic_types") ||
        !this.info.graphic_types.length
      ) {
        return [];
      }
      return _.orderBy(
        this.info.graphic_types,
        function (type) {
          return type.products.length;
        },
        ["desc"]
      );
    },
    paths() {
      return [
        {
          text: "Dashboard",
          disabled: false,
          route: { name: "default-content" },
        },
        {
          text: "Offers",
          disabled: false,
          route: { name: "offers-home" },
        },
        {
          text: this.category ? this.category.title : "Category",
          disabled: true,
          route: null,
        },
      ];
    },
    computed_all() {
      return (
        _.sumBy(this.category.graphic_types, "counts") + this.category.counts
      );
    },
    filters() {
      let list = _.cloneDeep(this.category.graphic_types);
      list.unshift({ title: "All", slug: "all" });
      return list;
    },
  },
  watch: {
    $route: {
      handler(val) {
        if (val) {
          this.getCategory(val.params.id);
          this.getSubCategoriesProducts(val.params.id);
        }
      },
      immediate: true,
      deep: true,
    },
    filter(val) {
      if (val === "all") val = this.$route.params.id;
      this.$router.push({
        name: "offers-by-subcategory",
        params: { id: val },
      });
    },
    category: {
      handler: function (val) {
        if (val && val.parent_id) {
          this.$router.push({
            name: "offers-by-subcategory",
            params: { id: val.slug },
          });
        }
      },
      deep: true,
      immediate: true,
    },
    searchKeyword(val) {
      if (this.searchResults.length > 0) return;
      if (this.isSearchLoading) return;

      this.isSearchLoading = true;
      request
        .get(
          `api/categories/${
            this.category.id
          }/search/products?keyword=${val.trim()}`
        )
        .then(({ data }) => {
          this.searchResults = data;
        })
        .finally(() => (this.isSearchLoading = false));
    },
    searchModel: {
      handler: function (val) {
        if (val) {
          this.viewProduct(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getCategory(id) {
      request
        .get(`api/categories/${id}`)
        .then(({ data }) => {
          this.category = data;
        })
        .finally(() => {
          this.$event.$emit("path-change", this.paths);
        });
    },
    getSubCategoriesProducts(slug) {
      this.loading_more = true;
      request
        .get(`api/categories/${slug}/sub-categories-products`)
        .then(({ data }) => {
          this.info = data;
        })
        .finally(() => (this.loading_more = false));
    },
    handleSaveAdminPriceByCategory(payload) {
      request
        .post(`api/categories/${this.category.id}/admin-prices`, payload)
        .then(({ data }) => {
          this.admin_price_by_category_dialog = false;
          this.appSnackbar( "Admin prices updated");
        });
    },
    openAddPriceByProduct(item) {
      this.product = item;
      this.admin_price_by_product_dialog = true;
    },
    handleSaveAdminPriceByProduct(payload) {
      request
        .post(`api/products/${this.product.id}/admin-prices`, payload)
        .then(({ data }) => {
          this.product = data;
          this.admin_price_by_product_dialog = false;
          this.appSnackbar( "Admin prices updated");
        });
    },
    add_to_likes(item, type_id) {
      this.likeUnlikeProduct(item, (data) => {
        let typeIndex = this.info.graphic_types.findIndex(
          (i) => i.id === type_id
        );
        if (~typeIndex) {
          let index = this.info.graphic_types[typeIndex].products.findIndex(
            (i) => i.id === item.id
          );
          if (~index) {
            this.info.graphic_types[typeIndex].products[index].is_liked =
              data.is_liked;
            this.info.graphic_types[typeIndex].products[index].likes_count =
              data.likes_count;
          }
        }
      });
    },
    delete_callback(data) {
      // let index = this.products.findIndex((i) => i.id === data.id);
      // if (~index) {
      //   this.products.splice(index, 1);
      // }
    },
  },
};
</script>

<style scoped lang="scss">
</style>