<template>
  <CustomDialog
    v-model="dialog"
    :title="title"
    :has-footer="false"
    :max-width="800"
  >
    <template v-slot:content>
      <v-card flat min-height="350">
        <v-card-text>
          <v-row dense class="mb-1" align="stretch" justify="space-between">
            <v-col
              class="
                border-1
                border-color--grey
                rounded-5
                d-flex
                pa-4
                flex-column
                align-start
                justify-start
              "
            >
              <v-sheet class="d-flex flex-column">
                <strong class="fw-700 fs-20 primary--text mr-auto mb-3">
                  Apply price to *:
                </strong>
                <v-row
                  align="start"
                  dense
                  class="flex-wrap d-flex align-self-start"
                  justify="start"
                  v-if="category && category.graphic_types.length > 0"
                >
                  <v-col
                    :cols="mdAndUp ? 6 : 12"
                    v-for="sub in category.graphic_types"
                    :key="sub.id"
                    class="py-0"
                  >
                    <v-checkbox
                      v-model="selected_subcategories"
                      hide-details="auto"
                      class="py-1 mt-1"
                      :value="sub.id"
                      :label="sub.title | ucwords"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col class="flex-column d-flex pa-4 align-center justify-center">
              <v-radio-group v-model="via" row class="my-2" hide-details="auto">
                <v-radio
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-marked"
                  value="fixed"
                  label="Fixed price"
                >
                </v-radio>
                <v-radio
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-marked"
                  value="percentage"
                  label="Percentage"
                >
                </v-radio>
              </v-radio-group>
              <v-sheet
                class="d-flex align-start flex-column"
                v-if="via === 'fixed'"
              >
                <label class="primary--text my-2 fw-500 fs-14 text-center">
                  Add additional price on every selected category by
                </label>
                <vuetify-money
                  outlined
                  clearable
                  dense
                  value-when-is-empty="0.0"
                  v-model="fixed_value"
                  class="fullwidth"
                  placeholder="Enter additional fixed price"
                  :options="options_1"
                ></vuetify-money>
              </v-sheet>
              <v-sheet
                class="d-flex align-center flex-column"
                v-if="via === 'percentage'"
              >
                <label class="primary--text my-2 fw-500 fs-14 text-center">
                  Add additional percentage on every selected category by
                </label>
                <div class="my-3 align-center d-flex justify-center flex-wrap">
                  <v-btn
                    @click="percentage_value = 5.0"
                    :color="percentage_value === 5.0 ? 'primary' : 'secondary'"
                    depressed
                    class="mr-1 text-none"
                  >
                    5%
                  </v-btn>
                  <v-btn
                    @click="percentage_value = 10.0"
                    :color="percentage_value === 10.0 ? 'primary' : 'secondary'"
                    depressed
                    class="text-none"
                  >
                    10%
                  </v-btn>
                  <v-btn
                    @click="percentage_value = 20.0"
                    :color="percentage_value === 20.0 ? 'primary' : 'secondary'"
                    depressed
                    class="mx-1 text-none"
                  >
                    20%
                  </v-btn>
                  <v-btn
                    @click="percentage_value = 30.0"
                    :color="percentage_value === 30.0 ? 'primary' : 'secondary'"
                    depressed
                    class="text-none"
                  >
                    30%
                  </v-btn>
                </div>
                <v-btn
                  block
                  color="light"
                  depressed
                  class="text-none primary--text border-1 rounded-10"
                  style="border-style: dotted"
                  @click="show_custom_percentage = !show_custom_percentage"
                >
                  Add custom percentage
                </v-btn>
                <vuetify-money
                  outlined
                  v-if="show_custom_percentage"
                  clearable
                  dense
                  :min="0.05"
                  :max="1000"
                  value-when-is-empty="0.0"
                  v-model="percentage_value"
                  class="fullwidth mt-3"
                  placeholder="Enter additional percentage on price"
                  :options="options_2"
                ></vuetify-money>
              </v-sheet>
              <v-btn
                width="150"
                @click="save"
                :disabled="selected_subcategories.length === 0"
                color="primary"
                class="align-self-center mb-3 mt-6 text-none"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-alert dense class="primary--text fw-700 fs-13">
            * Additional price will only be added on products that are from
            {{ settings.SiteName }}
          </v-alert>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
export default {
  name: "AdminPriceByCategoryDialog",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    category: { type: Object },
  },
  computed: {
    title() {
      return this.category ? `Add Admin Price for ${this.category.title}` : "";
    },
    payload() {
      return {
        via: this.via,
        via_value:
          this.via === "fixed" ? this.fixed_value : this.percentage_value,
        graphic_type_ids: this.selected_subcategories,
      };
    },
  },
  data: () => ({
    dialog: false,
    via: "percentage",
    percentage_value: 0.0,
    fixed_value: 0.0,
    selected_subcategories: [],
    show_custom_percentage: false,
    options_1: {
      locale: "en-US",
      prefix: "$",
      suffix: "",
      length: 11,
      precision: 2,
    },
    options_2: {
      locale: "en-US",
      prefix: "",
      suffix: "%",
      length: 6,
      precision: 2,
    },
  }),
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    save() {
      this.$emit("save", this.payload);
    },
  },
};
</script>
 <style>
</style>